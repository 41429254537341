exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-blog-index-tsx": () => import("./../../../src/pages/blog/index.tsx" /* webpackChunkName: "component---src-pages-blog-index-tsx" */),
  "component---src-pages-careers-index-tsx": () => import("./../../../src/pages/careers/index.tsx" /* webpackChunkName: "component---src-pages-careers-index-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-labs-index-tsx": () => import("./../../../src/pages/labs/index.tsx" /* webpackChunkName: "component---src-pages-labs-index-tsx" */),
  "component---src-pages-privacy-tsx": () => import("./../../../src/pages/privacy.tsx" /* webpackChunkName: "component---src-pages-privacy-tsx" */),
  "component---src-pages-services-ai-tsx": () => import("./../../../src/pages/services/ai.tsx" /* webpackChunkName: "component---src-pages-services-ai-tsx" */),
  "component---src-pages-services-data-engineering-tsx": () => import("./../../../src/pages/services/data-engineering.tsx" /* webpackChunkName: "component---src-pages-services-data-engineering-tsx" */),
  "component---src-pages-services-gcp-tsx": () => import("./../../../src/pages/services/gcp.tsx" /* webpackChunkName: "component---src-pages-services-gcp-tsx" */),
  "component---src-pages-services-index-tsx": () => import("./../../../src/pages/services/index.tsx" /* webpackChunkName: "component---src-pages-services-index-tsx" */),
  "component---src-pages-services-product-development-tsx": () => import("./../../../src/pages/services/product-development.tsx" /* webpackChunkName: "component---src-pages-services-product-development-tsx" */),
  "component---src-pages-services-team-augmentation-tsx": () => import("./../../../src/pages/services/team-augmentation.tsx" /* webpackChunkName: "component---src-pages-services-team-augmentation-tsx" */),
  "component---src-pages-team-tsx": () => import("./../../../src/pages/team.tsx" /* webpackChunkName: "component---src-pages-team-tsx" */),
  "component---src-pages-temp-tsx": () => import("./../../../src/pages/temp.tsx" /* webpackChunkName: "component---src-pages-temp-tsx" */),
  "component---src-templates-blog-post-tsx": () => import("./../../../src/templates/blogPost.tsx" /* webpackChunkName: "component---src-templates-blog-post-tsx" */),
  "component---src-templates-career-post-tsx": () => import("./../../../src/templates/careerPost.tsx" /* webpackChunkName: "component---src-templates-career-post-tsx" */)
}

